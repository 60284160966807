export enum EAvailablePath {
  ACCOUNT = '/account',
  ANALYTICS = '/analytics',
  CHECKLISTS = '/checklists',
  HOME = '/home',
  INTERACTIVE_REPORT = '/interactive-report',
  LIFE_SCIENCE = '/life-sciences',
  MULTI_FACTOR_AUTH = '/multi-factor-auth',
  NETWORK = '/network',
  PAYMENTS = '/payments',
  PAYMENTS_FOR_ACA = '/payments-for-aca',
  PRODUCE_REPORTS = '/produce-reports',
  REPORTS = '/reports',
  SUPPLIER_DOCUMENTS = '/supplier-documents',
  SUPPLIERS = '/suppliers',
}
