import { ELifeScienceStatusCode } from '@aca-new/app/pages/life-science/shared/enums/life-science-status-code.enum';
import { ELifeScienceStatus } from '@aca-new/app/pages/life-science/shared/enums/life-science-status.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dotLabelClass',
  pure: true,
  standalone: true,
})
export class DotLabelClassPipe implements PipeTransform {
  public transform(className: Readonly<string>, status?: Readonly<string>): string {
    const [suffix, label] = className.split('--');
    const pendingStatus = [
      ELifeScienceStatus.ON_HOLD_BY_QIMA,
      ELifeScienceStatus.SUSPEND_BY_CLIENT,
      ELifeScienceStatus.PENDING,
      ELifeScienceStatus.PENDING_PO,
      ELifeScienceStatus.PENDING_FEEDBACK,
      ELifeScienceStatus.PENDING_TEST_ITEM,
      ELifeScienceStatus.PENDING_PAYMENT,
    ];
    const acceptedStatus = [
      ELifeScienceStatus.ON_GOING,
      ELifeScienceStatus.RESULTS_READY,
      ELifeScienceStatus.COMPLETED,
      ELifeScienceStatus.IN_PROGRESS,
      ELifeScienceStatus.CREATED,
      ELifeScienceStatus.STUDY_REPORT,
    ];

    if (status === ELifeScienceStatusCode.REPORT_ARCHIVED) {
      return `${suffix}--${status}`;
    }

    if (pendingStatus.includes(label as ELifeScienceStatus)) {
      return `${suffix}--Pending`;
    }

    if (acceptedStatus.includes(label as ELifeScienceStatus)) {
      return `${suffix}--Accepted`;
    }

    return `${suffix}--${label}`;
  }
}
