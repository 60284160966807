export enum EPostMessageType {
  ACA_FRAME_RENDERED = 'acaFrameRendered',
  BOOK_FROM_PREVIOUS_ORDER = 'bookFromPreviousOrder',
  BUSINESS_SOLUTION_CHANGE = 'businessSolutionChange',
  COMPANY_LOGO_CHANGE = 'companyLogoChange',
  CREATE_CAP = 'createCAP',
  LANGUAGE_CHANGE = 'languageChange',
  LOAD = 'load',
  LOGOUT = 'Logout',
  MFA_DATA = 'MFAData',
  PATH_CHANGE = 'pathChange',
  PAY_BY_TRANSFER_SHOW = 'payByTransferShow',
  PAYMENT_MODAL_SHOW = 'paymentModalShow',
  RELOAD = 'reload',
  STATE_GO = 'stateGo',
  UPDATE_USER_DATA = 'updateUserData',
  VIEW_CAP = 'ViewCAP',
  VIEW_MORE_PREVIOUS_ORDERS = 'viewMorePreviousOrders',
  VIEW_ORDER = 'viewOrder',
  VIEW_ORDER_FILES = 'viewOrderFiles',
  VIEW_ORDER_ON_CALENDAR = 'viewOrderOnCalendar',
  VIEW_ORDER_ON_HOMEPAGE = 'viewOrderOnHomePage',
  VIEW_REPORT = 'viewReport',
}
