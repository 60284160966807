/* eslint-disable @typescript-eslint/naming-convention */

const COMMON_ENVIRONMENTS = {
  MARKETING_WEBSITE_URL: 'https://www.qima.com',
  MARKETING_WEBSITE_APP_LINK: '/app-marketing',
  SAMPLING_LEVELS_URL: 'https://www.qima.com/aql-acceptable-quality-limit',
  REFERENCE_SAMPLES: 'https://www.qima.com/reference-samples',
  GOOGLE_MAP_URL: 'https://www.google.com/maps/search/{location}/@39.9375346,115.837023,1z',
  CONDITIONS_URL: 'https://www.qima.com/conditions-of-service',
  TABLEAU_URL: 'https://dashboardspp.qima.com/',
  ABOUT_QIMA: 'https://www.qima.com/who-we-are',
  OUR_SERVICES: 'https://www.qima.com/quality-control-services',
  CONTACT_US: 'https://www.qima.com/contact-us',
  TERMS_CONDITIONS: 'https://www.qima.com/conditions-of-service',
  PRIVACY_POLICY: 'https://www.qima.com/privacy-policy',
  // SOCIAL MEDIA
  LINKEDIN: 'https://www.linkedin.com/company/qima/',
  TWITTER: 'https://twitter.com/qimagroup',
  WECHAT: 'https://mp.weixin.qq.com/s/gIvdIE7vDG0yCpAYNCdWZw',
  YOUTUBE: 'https://www.youtube.com/c/qimavideos',
  APPLE_STORE: 'https://itunes.apple.com/us/app/id589783645',
  PLAY_STORE: 'https://play.google.com/store/apps/details?id=com.asiainspection',
  FACEBOOK: 'https://www.facebook.com/qima',
  INSTAGRAM: 'https://www.instagram.com/qimagroup/',
};

export const ENVIRONMENT = {
  dev: {
    production: false,
    SERVER_URL: '/api-dev',
    ...COMMON_ENVIRONMENTS,
    INTERNAL_DOMAINS: ['devmy.qima.com', 'localhost', 'qima.dev'],
  },
  pp: {
    production: false,
    SERVER_URL: '/api-pp',
    ...COMMON_ENVIRONMENTS,
    INTERNAL_DOMAINS: ['preprodmy.qima.com', 'preprodmy.qima.cn', 'ppmy.qima.cn', 'localhost', 'qima.dev'],
  },
  staging: {
    production: false,
    SERVER_URL: '/api-staging',
    ...COMMON_ENVIRONMENTS,
    INTERNAL_DOMAINS: ['stagingmy.qima.com', 'localhost', 'qima.dev'],
  },
  staging_cert: {
    production: false,
    SERVER_URL: '/api-staging-cert',
    ...COMMON_ENVIRONMENTS,
    INTERNAL_DOMAINS: ['staging-certmy.qima.com', 'localhost', 'qima.dev'],
  },
  prod: {
    production: true,
    SERVER_URL: '/api-prod',
    ...COMMON_ENVIRONMENTS,
    MARKETING_WEBSITE_APP_LINK: 'https://www.qima.com',
    TABLEAU_URL: 'https://dashboards.qima.com/',
    INTERNAL_DOMAINS: [
      'my.qima.com',
      'my.qima.cn',
      'prodmy.qima.cn',
    ],
  },
};
