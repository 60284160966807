import { WelcomeAccountInfoComponent } from '@aca-new/app/pages/home/pages/welcome-account-info/welcome-account-info.component';
import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { isEmpty } from 'lodash/index';

@Injectable()
export class EntryGuard implements CanActivate {
  public constructor(
    private readonly _dialogService: DialogService,
    private readonly _appUserSettingService: AppUserSettingService,
    private readonly _appAuthenticationService: AppAuthenticationService
  ) {}

  public canActivate(): boolean | Promise<boolean> {
    this._initWelcomeAccountInfoDialog();

    return true;
  }

  private _initWelcomeAccountInfoDialog(): void {
    const userData: IUser = this._appAuthenticationService.getCurrentUser();

    if (isEmpty(userData)) {
      return;
    }

    if (!userData.company?.hearFrom && !this._appUserSettingService.getIsQimaProduce()) {
      this._dialogService.open(WelcomeAccountInfoComponent, { stopCloseWhenClickBackdrop: true });
    }
  }
}
