import { IAsideItem } from '@aca-new/app/pages/main/shared/components/aside/shared/models/interfaces/aside-item.interface';
import { EQimaIconName } from '@qima/ngx-qima';

export const MENU_ITEMS_COMMON: IAsideItem[] = [
  {
    id: 'bookings',
    label: 'APP_ASIDE.COMMON_ITEMS.BOOKINGS',
    iconName: EQimaIconName.ASSIGNMENTS,
    matchPath: '/bookings/',
    path: '/bookings/open',
    tooltip: 'APP_ASIDE.COMMON_ITEMS.BOOKINGS',
  },
  {
    id: 'reports',
    label: 'APP_ASIDE.COMMON_ITEMS.REPORTS',
    iconName: EQimaIconName.REPORTS,
    matchPath: '/reports',
    path: '/reports',
    tooltip: 'APP_ASIDE.COMMON_ITEMS.REPORTS',
  },
  {
    id: 'analytics',
    label: 'APP_ASIDE.COMMON_ITEMS.ANALYTICS',
    iconName: EQimaIconName.ANALYTICS,
    matchPath: '/analytics',
    path: '/analytics',
    tooltip: 'APP_ASIDE.COMMON_ITEMS.ANALYTICS',
  },
  {
    id: 'suppliers',
    label: 'APP_ASIDE.COMMON_ITEMS.SUPPLIERS',
    iconName: EQimaIconName.SUPPLIER_FACTORY,
    matchPath: '/suppliers',
    path: '/suppliers',
    tooltip: 'APP_ASIDE.COMMON_ITEMS.SUPPLIERS',
  },
  {
    id: 'checklists',
    label: 'APP_ASIDE.COMMON_ITEMS.CHECKLISTS',
    iconName: EQimaIconName.CHECKLIST,
    matchPath: '/checklists',
    path: '/checklists',
    tooltip: 'APP_ASIDE.COMMON_ITEMS.CHECKLISTS',
  },
  {
    id: 'units',
    label: 'APP_ASIDE.COMMON_ITEMS.UNITS',
    iconName: EQimaIconName.SUPPLIER_FACTORY,
    matchPath: '',
    path: '',
    tooltip: 'APP_ASIDE.COMMON_ITEMS.UNITS',
  },
  {
    id: 'supplierDocuments',
    label: 'APP_ASIDE.COMMON_ITEMS.SUPPLIER_DOCUMENTS',
    iconName: EQimaIconName.DOCUMENT,
    matchPath: '/supplier-documents',
    path: '/supplier-documents',
    tooltip: 'APP_ASIDE.COMMON_ITEMS.SUPPLIER_DOCUMENTS',
  },
  {
    id: 'network',
    label: 'APP_ASIDE.COMMON_ITEMS.NETWORK',
    iconName: EQimaIconName.NETWORK,
    matchPath: '/network',
    path: '/network',
    tooltip: 'APP_ASIDE.COMMON_ITEMS.NETWORK',
  },
  {
    id: 'payments',
    label: 'APP_ASIDE.COMMON_ITEMS.PAYMENTS',
    iconName: EQimaIconName.PAYMENT,
    matchPath: '/payments/',
    path: '/payments/unpaid',
    tooltip: 'APP_ASIDE.COMMON_ITEMS.PAYMENTS',
  },
];

export const MENU_ITEMS_LIFE_SCIENCE: IAsideItem[] = [
  {
    id: 'home',
    visible: true,
    label: 'LIFE_SCIENCE.HOME.HOME',
    iconName: EQimaIconName.DASHBOARD,
    matchPath: '/life-sciences',
    path: '/life-sciences',
    tooltip: 'LIFE_SCIENCE.HOME.HOME',
  },
  {
    id: 'studies',
    visible: true,
    label: 'LIFE_SCIENCE.STUDIES.STUDIES',
    iconName: EQimaIconName.INSPECTION,
    matchPath: '/life-sciences/studies',
    path: '/life-sciences/studies',
    tooltip: 'LIFE_SCIENCE.STUDIES.STUDIES',
  },
  {
    id: 'reports',
    visible: true,
    label: 'LIFE_SCIENCE.REPORTS.REPORTS',
    iconName: EQimaIconName.REPORTS,
    matchPath: '/life-sciences/reports',
    path: '/life-sciences/reports',
    tooltip: 'LIFE_SCIENCE.REPORTS.REPORTS',
  },
];
