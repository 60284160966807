export enum ELifeScienceStatusCode {
  ARCHIVED = '5000',
  COMPLETED_PENDING_PAYMENTS = '401',
  COMPLETED_RESULTS_READY = '4000',
  CREATED = '1000',
  CREATED_ON_HOLD_BY_QIMA = '1140',
  CREATED_PENDING_FEEDBACK = '1130',
  CREATED_PENDING_PO = '1110',
  CREATED_PENDING_TEST_ITEM = '1120',
  CREATED_SUSPEND_BY_CLIENT = '1150',
  FINALIZED_ON_HOLD_BY_QIMA = '3020',
  FINALIZED_PENDING_FEEDBACK = '3010',
  FINALIZED_PENDING_PAYMENTS = '304',
  FINALIZED_RESULTS_READY = '3000',
  FINALIZED_SUSPEND_BY_CLIENT = '3030',
  NOT_KNOWN = '0',
  REPORT_ARCHIVED = '9030',
  REPORT_PENDING = '9000',
  REPORT_READY = '9020',
  RESULT_READY = '9010',
  STARTED_IN_PROGRESS = '2000',
  STARTED_ON_HOLD_BY_QIMA = '2020',
  STARTED_PENDING_FEEDBACK = '2010',
  STARTED_SUSPEND_BY_CLIENT = '2030',
}
