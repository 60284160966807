<div
  cdkScrollable
  class="welcome-account-info"
>
  <header class="welcome-account-info__title">{{ 'WELCOME_ACCOUNT_INFO.TITLE' | translate }}</header>
  <div
    *ngIf="welcomeAccountInfoForm"
    [formGroup]="welcomeAccountInfoForm"
    class="content scroll-area"
  >
    <qima-block-banner
      [qimaBlockBannerMessage]="'WELCOME_ACCOUNT_INFO.DESCRIPTION' | translate"
      [qimaBlockBannerType]="bannerTypeInfo"
      class="qima-mt-m"
    ></qima-block-banner>
    <div class="welcome-account-info__block">
      <div class="welcome-account-info__block-title">{{ 'WELCOME_ACCOUNT_INFO.ABOUT_YOU' | translate }}</div>
      <div class="app-flex">
        <app-form-item
          formItemLabel="WELCOME_ACCOUNT_INFO.FIRST_NAME"
          [formItemOrientation]="orientationVertical"
          [formItemVerticalAlign]="labelVerticalTop"
          class="qima-mr-m"
        >
          <qima-input-text
            [formControl]="contactFirstName"
            [qimaInputTextSize]="inputSizeLarge"
            qimaInputTextIsDisabled="true"
          ></qima-input-text>
        </app-form-item>
        <app-form-item
          formItemLabel="WELCOME_ACCOUNT_INFO.LAST_NAME"
          [formItemOrientation]="orientationVertical"
          [formItemVerticalAlign]="labelVerticalTop"
        >
          <qima-input-text
            [formControl]="contactLastName"
            [qimaInputTextSize]="inputSizeLarge"
            qimaInputTextIsDisabled="true"
          ></qima-input-text>
        </app-form-item>
      </div>
      <app-form-item
        [formItemControl]="welcomeAccountInfoForm.controls.userPosition"
        [formItemOrientation]="orientationVertical"
        [formItemVerticalAlign]="labelVerticalTop"
        formItemLabel="WELCOME_ACCOUNT_INFO.POSITION"
      >
        <qima-input-text
          [qimaInputTextSize]="inputSizeLarge"
          [qimaInputTextPlaceholder]="'WELCOME_ACCOUNT_INFO.POSITION_PLACEHOLDER' | translate"
          formControlName="userPosition"
        ></qima-input-text>
      </app-form-item>
    </div>
    <div class="welcome-account-info__block">
      <div class="welcome-account-info__block-title">{{ 'WELCOME_ACCOUNT_INFO.ABOUT_THE_COMPANY' | translate }}</div>
      <app-form-item
        formItemLabel="WELCOME_ACCOUNT_INFO.COMPANY_NAME"
        [formItemOrientation]="orientationVertical"
        [formItemVerticalAlign]="labelVerticalTop"
      >
        <qima-input-text
          qimaInputTextIsDisabled="true"
          [qimaInputTextSize]="inputSizeLarge"
          [formControl]="contactCompanyName"
        ></qima-input-text>
      </app-form-item>
      <app-form-item
        [formItemControl]="welcomeAccountInfoForm.controls.companyAddress"
        [formItemOrientation]="orientationVertical"
        [formItemVerticalAlign]="labelVerticalTop"
        formItemLabel="WELCOME_ACCOUNT_INFO.ADDRESS"
      >
        <qima-input-textarea
          [qimaInputTextareaPlaceholder]="'WELCOME_ACCOUNT_INFO.ADDRESS_PLACEHOLDER' | translate"
          formControlName="companyAddress"
        ></qima-input-textarea>
      </app-form-item>
      <app-form-item
        [formItemControl]="welcomeAccountInfoForm.controls.companyCountry"
        formItemLabel="WELCOME_ACCOUNT_INFO.COUNTRY"
        [formItemOrientation]="orientationVertical"
        [formItemVerticalAlign]="labelVerticalTop"
      >
        <qima-input-search-select
          [qimaInputSearchSelectHasClearButton]="true"
          [qimaInputSearchSelectHasValidators]="true"
          [qimaInputSearchSelectIsLoading]="!countries.length"
          [qimaInputSearchSelectPlaceholder]="'WELCOME_ACCOUNT_INFO.COUNTRY_PLACEHOLDER' | translate"
          [qimaInputSearchSelectSize]="inputSizeLarge"
          formControlName="companyCountry"
        >
          <qima-menu>
            <qima-menu-item-text
              *ngFor="let item of countries"
              [qimaMenuItemTextId]="item.value"
              [qimaMenuItemTextLabel]="item.label | translate"
              [qimaMenuItemTextValue]="item.value"
              appNgForTrackByUuid
            ></qima-menu-item-text>
          </qima-menu>
        </qima-input-search-select>
      </app-form-item>
      <app-form-item
        [formItemControl]="welcomeAccountInfoForm.controls.companyState"
        [formItemOrientation]="orientationVertical"
        [formItemVerticalAlign]="labelVerticalTop"
        formItemLabel="WELCOME_ACCOUNT_INFO.STATE"
      >
        <qima-input-search-select
          [qimaInputSearchSelectHasClearButton]="true"
          [qimaInputSearchSelectHasValidators]="true"
          [qimaInputSearchSelectIsDisabled]="!states.length"
          [qimaInputSearchSelectIsLoading]="!states.length && !!welcomeAccountInfoForm.controls.companyCountry.value"
          [qimaInputSearchSelectPlaceholder]="'WELCOME_ACCOUNT_INFO.STATE_PLACEHOLDER' | translate"
          [qimaInputSearchSelectSize]="inputSizeLarge"
          formControlName="companyState"
        >
          <qima-menu>
            <qima-menu-item-text
              *ngFor="let item of states"
              [qimaMenuItemTextId]="item.value"
              [qimaMenuItemTextLabel]="item.label | translate"
              [qimaMenuItemTextValue]="item.value"
              appNgForTrackByUuid
            ></qima-menu-item-text>
          </qima-menu>
        </qima-input-search-select>
      </app-form-item>
      <app-form-item
        [formItemControl]="welcomeAccountInfoForm.controls.companyCity"
        [formItemOrientation]="orientationVertical"
        [formItemVerticalAlign]="labelVerticalTop"
        formItemLabel="WELCOME_ACCOUNT_INFO.CITY"
      >
        <qima-input-text
          [qimaInputTextPlaceholder]="'WELCOME_ACCOUNT_INFO.CITY_PLACEHOLDER' | translate"
          [qimaInputTextSize]="inputSizeLarge"
          formControlName="companyCity"
        ></qima-input-text>
      </app-form-item>
    </div>
    <div class="welcome-account-info__block">
      <div class="welcome-account-info__block-title">{{ 'WELCOME_ACCOUNT_INFO.BILLING_CONTACT' | translate }}</div>
      <div class="welcome-account-info__block__description">
        <qima-icon
          class="info-icon"
          [qimaIconName]="qimaIcon.iconName.INFO"
          [qimaIconSize]="qimaIcon.sizeMedium"
        ></qima-icon>
        <span>{{ 'WELCOME_ACCOUNT_INFO.BILLING_CONTACT_DESCRIPTION' | translate }}</span>
      </div>
      <div class="welcome-account-info__block__billing-account">
        <qima-input-checkbox [formControl]="sameContact"></qima-input-checkbox>
        <span>{{ 'WELCOME_ACCOUNT_INFO.YOU_ARE_CONTACT' | translate }}</span>
      </div>
      <div class="app-flex app-justify-content-between">
        <app-form-item
          [formItemControl]="welcomeAccountInfoForm.controls.billingGivenName"
          [formItemOrientation]="orientationVertical"
          [formItemVerticalAlign]="labelVerticalTop"
          class="welcome-account-info__block__billing-account__name"
          formItemLabel="WELCOME_ACCOUNT_INFO.FIRST_NAME"
        >
          <qima-input-text
            [qimaInputTextIsDisabled]="sameContact.value === checkboxStateEnum.CHECKED"
            [qimaInputTextSize]="inputSizeLarge"
            formControlName="billingGivenName"
          ></qima-input-text>
        </app-form-item>
        <app-form-item
          [formItemControl]="welcomeAccountInfoForm.controls.billingFamilyName"
          class="welcome-account-info__block__billing-account__name"
          [formItemOrientation]="orientationVertical"
          [formItemVerticalAlign]="labelVerticalTop"
          formItemLabel="WELCOME_ACCOUNT_INFO.LAST_NAME"
        >
          <qima-input-text
            [qimaInputTextIsDisabled]="sameContact.value === checkboxStateEnum.CHECKED"
            [qimaInputTextSize]="inputSizeLarge"
            formControlName="billingFamilyName"
          ></qima-input-text>
        </app-form-item>
      </div>
      <app-form-item
        [formItemControl]="welcomeAccountInfoForm.controls.billingEmail"
        [formItemOrientation]="orientationVertical"
        [formItemVerticalAlign]="labelVerticalTop"
        formItemLabel="WELCOME_ACCOUNT_INFO.EMAIL"
      >
        <app-email
          [emailIsDisabled]="sameContact.value === checkboxStateEnum.CHECKED"
          emailHasValidators="true"
          emailIsRequired="true"
          [emailPlaceholder]="'WELCOME_ACCOUNT_INFO.EMAIL_PLACEHOLDER' | translate"
          formControlName="billingEmail"
        ></app-email>
      </app-form-item>
      <app-form-item
        [formItemControl]="welcomeAccountInfoForm.controls.hearFrom"
        [formItemOrientation]="orientationVertical"
        [formItemVerticalAlign]="labelVerticalTop"
        formItemLabel="WELCOME_ACCOUNT_INFO.HOW_KNOW_US"
      >
        <qima-input-select
          [qimaInputSelectIsLoading]="!marketingSelects.length"
          [qimaInputSelectPlaceholder]="'WELCOME_ACCOUNT_INFO.HOW_KNOW_US_PLACEHOLDER' | translate"
          [qimaInputSelectSize]="inputSizeLarge"
          formControlName="hearFrom"
        >
          <qima-menu>
            <qima-menu-item-text
              (qimaMenuItemTextClick)="onHearFromItemClick(item)"
              *ngFor="let item of marketingSelects"
              [qimaMenuItemTextId]="item.value"
              [qimaMenuItemTextLabel]="item.label | translate"
              [qimaMenuItemTextValue]="item.value"
              appNgForTrackByUuid
            ></qima-menu-item-text>
          </qima-menu>
        </qima-input-select>
      </app-form-item>
      <app-form-item
        [@qimaAccordion]="!!subSelects.length"
        [formItemControl]="welcomeAccountInfoForm.controls.hearFromSub"
        [formItemLabel]="subLabel"
        [formItemOrientation]="orientationVertical"
        [formItemVerticalAlign]="labelVerticalTop"
        class="app-flex overflow-hidden"
      >
        <qima-input-select
          [qimaInputSelectPlaceholder]="'WELCOME_ACCOUNT_INFO.HOW_KNOW_US_PLACEHOLDER' | translate"
          [qimaInputSelectSize]="inputSizeLarge"
          formControlName="hearFromSub"
        >
          <qima-menu>
            <qima-menu-item-text
              (qimaMenuItemTextClick)="onHearFromSubItemClick(item)"
              *ngFor="let item of subSelects"
              [qimaMenuItemTextId]="item.value"
              [qimaMenuItemTextLabel]="item.label | translate"
              [qimaMenuItemTextValue]="item.value"
              appNgForTrackByUuid
            ></qima-menu-item-text>
          </qima-menu>
        </qima-input-select>
      </app-form-item>
      <app-form-item
        [@qimaAccordion]="!!subSubSelects.length"
        [formItemControl]="welcomeAccountInfoForm.controls.hearFromSubSub"
        class="app-flex overflow-hidden"
        [formItemOrientation]="orientationVertical"
        [formItemVerticalAlign]="labelVerticalTop"
        formItemLabel="WELCOME_ACCOUNT_INFO.SELECT_EVENTS"
      >
        <qima-input-select
          [qimaInputSelectPlaceholder]="'WELCOME_ACCOUNT_INFO.HOW_KNOW_US_PLACEHOLDER' | translate"
          [qimaInputSelectSize]="inputSizeLarge"
          formControlName="hearFromSubSub"
        >
          <qima-menu>
            <qima-menu-item-text
              *ngFor="let item of subSubSelects"
              [qimaMenuItemTextId]="item.value"
              [qimaMenuItemTextLabel]="item.label | translate"
              [qimaMenuItemTextValue]="item.value"
              appNgForTrackByUuid
            ></qima-menu-item-text>
          </qima-menu>
        </qima-input-select>
      </app-form-item>
    </div>
  </div>

  <div #submitRef>
    <qima-button
      (qimaButtonClick)="onSubmit()"
      [qimaButtonLabel]="'COMMON.SUBMIT' | translate"
      [qimaButtonSize]="buttonSizeLarge"
      [qimaButtonWidth]="buttonWidthFull"
      class="foot"
    ></qima-button>
  </div>
</div>
