import { AsideService } from '@aca-new/app/pages/main/shared/components/aside/shared/services/aside.service';
import { FreshChatService } from '@aca-new/app/pages/main/shared/services/fresh-chat.service';
import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { UserService } from '@aca-new/app/pages/user/shared/service/user.service';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { EChannelKeys } from '@aca-new/app/shared/models/enums/channel-keys.enum';
import { EPostMessageType } from '@aca-new/app/shared/models/enums/post-message-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { AcaMessageService } from '@aca-new/app/shared/services/post-message-services/aca-message/aca-message.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { QimaResponsiveService } from '@qima/ngx-qima';
import { filter, Observable, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MainComponent implements OnInit, AfterViewInit {
  public isAsideCollapsed: boolean = false;
  public isLoading: boolean = false;
  public isAcaFrameVisible = true;
  public acaUrl: string = '';
  public isMobile: boolean = false;
  public readonly securityContext = SecurityContext;

  public constructor(
    private readonly _router: Router,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _asideService: AsideService,
    private readonly _translateService: TranslateService,
    private readonly _acaMessageService: AcaMessageService,
    private readonly _qimaResponsiveService: QimaResponsiveService,
    private readonly _appAuthenticationService: AppAuthenticationService,
    private readonly _appOverlayService: AppOverlayService,
    private readonly _freshChatService: FreshChatService,
    private readonly _storageService: StorageService,
    private readonly _userService: UserService,
    @Inject(WINDOW) private readonly _window: IMyWindow
  ) {}

  public ngOnInit(): void {
    this._watch();
    this._initData();
  }

  public ngAfterViewInit(): void {
    const userData: IUser = this._appAuthenticationService.getCurrentUser();

    if (this._appAuthenticationService.isAuthenticated()) {
      this._initChat(userData);
    }

    const { currentUserSubject$ } = this._appAuthenticationService;

    currentUserSubject$.pipe(untilDestroyed(this)).subscribe((data: IUser): void => {
      this._initChat(data);
    });
  }

  private _initChat(userData: IUser): void {
    this._freshChatService.initChat(userData);
  }

  private _initData(): void {
    this._updateAcaFrameStatus(this._router.url);
    this.acaUrl = this._acaMessageService.getAcaFrameUrl(this._router.url);
  }

  private _watch(): void {
    this._asideService.isAsideCollapsed$.pipe(untilDestroyed(this)).subscribe((isAsideCollapsed: boolean): void => {
      this.isAsideCollapsed = isAsideCollapsed;
      this._changeDetectorRef.markForCheck();
    });

    this._appOverlayService.isLoading$.pipe(untilDestroyed(this)).subscribe((isLoading: boolean): void => {
      this.isLoading = isLoading;
      this._changeDetectorRef.detectChanges();
    });

    this._qimaResponsiveService
      .isMobile$()
      .pipe(untilDestroyed(this))
      .subscribe((isMobile: boolean): void => {
        this.isMobile = isMobile;
      });

    if (this._acaMessageService.isAcaFrameAvailable) {
      this._watchRouterChange$().pipe(untilDestroyed(this)).subscribe();
      this._acaMessageService.watchMessageFromAca();
    }

    this._updateLoginInfo();
    this._updateUserData();
  }

  private _updateLoginInfo(): void {
    const channel = new BroadcastChannel(EChannelKeys.LOGIN_AGAIN);

    channel.onmessage = (event): void => {
      const { params, user } = event.data;
      const userId = this._storageService.getItem(EStorageKeys.USER_ID);

      if (!params || !user || userId === params.userId) {
        return;
      }

      this._userService.saveAuthenticationData(params as Params);
      this._userService.updateUserDataSession(user as IUser);
      void this._router.navigate(['/home']).then((isNavigated: boolean): void => {
        if (isNavigated) {
          this._window.location.reload();
        }
      });
    };
  }

  private _updateUserData(): void {
    const channel = new BroadcastChannel(EChannelKeys.UPDATE_USER_DATA);

    channel.onmessage = (event): void => {
      const { user } = event.data;

      if (user) {
        this._userService.updateUserDataSession(user as IUser);
        this._window.location.reload();
      }
    };
  }

  private _watchRouterChange$(): Observable<Event> {
    return this._router.events.pipe(
      filter((event: Event): boolean => {
        return event instanceof NavigationStart || event instanceof NavigationEnd;
      }),
      tap({
        next: (event: Event): void => {
          if (event instanceof NavigationStart) {
            if (event.navigationTrigger === 'popstate' && !this._acaMessageService.isMyQimaPageAvailable(event.url)) {
              this._acaMessageService.informAcaPathChange(event.url.replace('/aca', ''));
            }
          } else if (event instanceof NavigationEnd) {
            this._updateAcaFrameStatus(event.urlAfterRedirects);
          }
        },
      })
    );
  }

  private _updateAcaFrameStatus(url: string): void {
    const wasAcaFrameVisible = this.isAcaFrameVisible;

    this.isAcaFrameVisible = !this._acaMessageService.isMyQimaPageAvailable(url) && url.startsWith('/aca');

    if (this.isAcaFrameVisible && !wasAcaFrameVisible) {
      this._acaMessageService.postMessageToAca({
        type: EPostMessageType.LANGUAGE_CHANGE,
        data: { language: this._translateService.currentLang },
      });
    }

    this._changeDetectorRef.markForCheck();
  }
}
