import { ILabTestingInvoices } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/lab-testing-invoice.interface';
import { IOrderFee } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/order-fee.interface';
import { IPaymentResponse } from '@aca-new/app/pages/payments/shared/models/interfaces/payment-response.interface';
import { PaymentsService } from '@aca-new/app/pages/payments/shared/services/payment/payments.service';
import { ICurrencyTableCell } from '@aca-new/app/shared/components/currency-table-cell/shared/models/interfaces/currency-table-cell.interface';
import { ESortOrder } from '@aca-new/app/shared/components/data-table/shared/models/enums/sort-order.enum';
import { PageItemType } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-data.interface';
import { IDataTableQueryParams } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-query-params.interface';
import { ETableDataType } from '@aca-new/app/shared/components/table/shared/enums/table-data-type.enum';
import { ITableBodyCell, ITableBodyRow } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { IInvoiceResponse } from '@aca-new/app/shared/models/interfaces/invoice-response.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { Injectable } from '@angular/core';
import { IQimaFile, QimaOptionalType } from '@qima/ngx-qima';
import { isEmpty } from 'lodash/index';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AbstractDefaultPaymentService {
  public queryParams: IDataTableQueryParams = {
    keyword: '',
    pageSize: 1,
    page: 1,
    sortField: 'inspectionDate',
    sortType: ESortOrder.DESC,
  };

  public constructor(protected readonly _paymentsService: PaymentsService) {}

  public isEnablePayByTransfer(): boolean {
    return false;
  }

  public generateProformaInvoice$(orderId: string): Observable<QimaNullableType<IInvoiceResponse>> {
    return this._paymentsService.generateProformaInvoice$(orderId);
  }

  public reGenerateProformaInvoice$(proformaInvoiceNumbers: string): Observable<QimaNullableType<IInvoiceResponse[] | IInvoiceResponse>> {
    return this._paymentsService.refreshGenerateProformaInvoice$(proformaInvoiceNumbers);
  }

  public getReIssueIds(selectedRows: ITableBodyRow[]): string[] {
    const ids: string[] = [];

    selectedRows.forEach((row: ITableBodyRow): void => {
      const paymentResponse: IPaymentResponse = row.originData as IPaymentResponse;

      if (!isEmpty(paymentResponse.proformaInvoiceNumber)) {
        ids.push(paymentResponse.proformaInvoiceNumber);
      }
    });

    return ids;
  }

  public payByTransfer$(orderIds: string[], orderFees: IOrderFee[], uploadFiles: IQimaFile[]): Observable<IHttpResponseBody<undefined>> {
    return this._paymentsService.payByTransfer$(orderIds, orderFees, uploadFiles);
  }

  public getOrderFees$(orderIds: string[], orderFees: IOrderFee[], orderIdToRefMap: Record<string, PageItemType>): Observable<QimaOptionalType<IOrderFee[] | ILabTestingInvoices>> {
    return this._paymentsService.getOrderFees$(orderIds, orderFees, orderIdToRefMap);
  }

  public beforeCheckboxClick(_rows: ITableBodyRow[]): boolean {
    return true;
  }

  public currencySortFunction(s1: ITableBodyCell, s2: ITableBodyCell, order: ESortOrder): number {
    if (!s1 || !s2 || s1.type !== ETableDataType.CUSTOMIZED || s2.type !== ETableDataType.CUSTOMIZED) {
      return -1;
    }

    const data1 = parseFloat(((s1.customizedCellConfiguration?.data as ICurrencyTableCell).value || '0').toString().replace(/,/g, ''));
    const data2 = parseFloat(((s2.customizedCellConfiguration?.data as ICurrencyTableCell).value || '0').toString().replace(/,/g, ''));

    if (order === ESortOrder.ASC) {
      return data1 - data2;
    }

    return data2 - data1;
  }
}
