<div
  *ngIf="cellData"
  class="common-table-cell"
>
  <!--  ngx qima cell-->
  <qima-table-body-cell-text
    *ngIf="cellData.type === dataType.STRING || (cellData.type === dataType.LINK && !cellData.label)"
    [qimaAutoTooltipText]="cellData.label || '' | translate"
    [qimaTableBodyCellTextLabel]="cellData.label || '---' | translate"
    [style.width.px]="width"
    qimaAutoTooltip
  ></qima-table-body-cell-text>

  <qima-table-body-cell-text
    *ngIf="cellData.type === dataType.NUMBER"
    [qimaAutoTooltipText]="cellData.prefix ? cellData.prefix + ' ' + (cellData.label | number) : (cellData.label | number)"
    [qimaTableBodyCellTextLabel]="cellData.prefix ? cellData.prefix + ' ' + (cellData.label | number) : (cellData.label | number)"
    [style.width.px]="width"
    qimaAutoTooltip
  ></qima-table-body-cell-text>

  <qima-table-body-cell-text
    *ngIf="cellData.type === dataType.DATE"
    [qimaAutoTooltipText]="(cellData.label | date: 'dd-MMM-yyyy') || '---'"
    [qimaTableBodyCellTextLabel]="(cellData.label | date: 'dd-MMM-yyyy') || '---'"
    [style.width.px]="width"
    qimaAutoTooltip
  ></qima-table-body-cell-text>

  <ng-container *ngIf="cellData.type === dataType.ICE_CUBE && cellData.iceCubeStyle">
    <qima-table-body-cell-ice-cube
      *ngIf="!cellData.icon"
      [qimaTableBodyCellIceCubeLabel]="cellData.label"
      [qimaTableBodyCellIceCubeStyle]="cellData.iceCubeStyle"
      [qimaTooltipText]="cellData.label || '' | serviceTypeFullName | translate"
      [style.width.px]="width"
      qimaTooltip
    ></qima-table-body-cell-ice-cube>

    <qima-table-body-cell-ice-cube-icon
      *ngIf="cellData.icon"
      [qimaTableBodyCellIceCubeIconIconName]="cellData.icon"
      [qimaTableBodyCellIceCubeIconStyle]="cellData.iceCubeStyle"
    >
    </qima-table-body-cell-ice-cube-icon>
  </ng-container>

  <app-progress-indicator-with-arrow
    *ngIf="cellData.type === dataType.PROGRESS_INDICATOR"
    [progressIndicatorWithArrowValue]="cellData.progressIndicatorValue || 0"
    [progressIndicatorWithArrowTooltip]="cellData.tooltip || '' | translate"
  ></app-progress-indicator-with-arrow>

  <qima-table-body-cell-badge
    *ngIf="cellData.type === dataType.BADGE && cellData.label"
    [qimaTableBodyCellBadgeHasBorder]="true"
    [qimaTableBodyCellBadgeLabel]="(cellData.badgeStyle ? cellData.label : (cellData.label || '' | translate | tableBadgeLabel)) + cellData.suffix"
    [qimaTableBodyCellBadgeStyle]="cellData.badgeStyle || (cellData.label | tableBadgeStyle)"
    [style.width.px]="width"
  ></qima-table-body-cell-badge>

  <div
    *ngIf="cellData.type === dataType.BADGE && !cellData.label"
    [style.width.px]="width"
  >
    <span class="qima-ml-xs">---</span>
  </div>

  <!--  custom cell-->
  <div
    *ngIf="cellData.type === dataType.LINK && cellData.label"
    [qimaAutoTooltipHasArrow]="true"
    [qimaAutoTooltipPosition]="qimaTooltip.position.TOP"
    [qimaAutoTooltipText]="cellData.label || '' | translate"
    [style.width.px]="width"
    class="common-table-cell__link"
    qimaAutoTooltip
  >
    {{ cellData.label | translate }}
  </div>

  <div
    *ngIf="cellData.type === dataType.DOT_LABEL"
    [style.width.px]="width"
    class="common-table-cell__dot"
  >
    <qima-dot
      [ngClass]="['common-table-cell__dot--' + cellData.label | dotLabelClass: cellData.prefix ?? '']"
      [qimaDotSize]="qimaDot.dotSize.MIN"
      class="qima-mr-base"
    ></qima-dot>
    <span
      [ngClass]="['common-table-cell__dot__label--' + cellData.label | dotLabelClass: cellData.prefix ?? '']"
      class="common-table-cell__dot__label"
      >{{ cellData.label }}
    </span>
  </div>

  <div
    *ngIf="cellData.type === dataType.EMPTY"
    [style.width.px]="width"
  ></div>

  <ng-container *ngIf="cellData.type === dataType.ICON">
    <qima-icon
      (click)="onIconCellClick()"
      [qimaIconName]="cellData.icon"
      [style.width.px]="width"
      class="common-table-cell__icon"
    ></qima-icon>
  </ng-container>

  <app-decision-label-cell
    *ngIf="cellData.type === dataType.DECISION_LABEL_CELL"
    [decisionLabelCellCellData]="cellData"
    [decisionLabelCellOriginData]="originData"
    [style.width.px]="width"
  >
  </app-decision-label-cell>

  <app-multiple-label-cell
    *ngIf="cellData.type === dataType.MULTIPLE_LABEL_CELL"
    [multipleLabelCellCellData]="cellData"
    [style.width.px]="width"
  ></app-multiple-label-cell>
</div>
