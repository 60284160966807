import { AccountService } from '@aca-new/app/pages/account/shared/services/account.service';
import { EMarketingCategoryKey } from '@aca-new/app/pages/home/pages/welcome-account-info/shared/enums/marketling-category-key.enum';
import { IMarketingListDom } from '@aca-new/app/pages/home/pages/welcome-account-info/shared/interfaces/marketing-list.interface';
import { IMarketingSelect } from '@aca-new/app/pages/home/pages/welcome-account-info/shared/interfaces/marketing-select.interface';
import { IWelcomeAccountInfo } from '@aca-new/app/pages/home/pages/welcome-account-info/shared/interfaces/welcome-account-info.interface';
import { WelcomeAccountInfoService } from '@aca-new/app/pages/home/pages/welcome-account-info/shared/services/welcome-account-info.service';
import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { EmailModule } from '@aca-new/app/shared/components/email/email.module';
import { FormItemModule } from '@aca-new/app/shared/components/form-item/form-item.module';
import { EFormItemVerticalAlign } from '@aca-new/app/shared/components/form-item/shared/models/enums/form-item-vertical-align.enum';
import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { EChannelKeys } from '@aca-new/app/shared/models/enums/channel-keys.enum';
import { EOrientation } from '@aca-new/app/shared/models/enums/orientation.enum';
import { ISelect } from '@aca-new/app/shared/models/interfaces/select.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { AppParamsService } from '@aca-new/app/shared/services/book-services/app-params/app-params.service';
import { AppScrollService } from '@aca-new/app/shared/services/book-services/app-scroll/app-scroll.service';
import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { AppSnackbarService } from '@aca-new/app/shared/services/modal-services/app-snackbar/app-snackbar.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { CdkScrollable } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ControlsOf } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import {
  EQimaBlockBannerType,
  EQimaButtonSize,
  EQimaButtonWidth,
  EQimaCheckboxState,
  EQimaInputSize,
  EQimaSnackbarType,
  QIMA_QUICK_ACCORDION_ANIMATION,
  QimaBlockBannerModule,
  QimaButtonModule,
  QimaIconModule,
  QimaInputCheckboxModule,
  QimaInputMultipleEmailModule,
  QimaInputSearchSelectModule,
  QimaInputSelectModule,
  QimaInputTextareaModule,
  QimaInputTextModule,
  QimaMenuItemTextModule,
  QimaMenuModule,
  QimaOptionalType,
} from '@qima/ngx-qima';
import { debounceTime, Observable, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-welcome-account-info',
  templateUrl: './welcome-account-info.component.html',
  styleUrls: ['./welcome-account-info.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WelcomeAccountInfoService, AccountService],
  animations: [QIMA_QUICK_ACCORDION_ANIMATION],
  imports: [
    CdkScrollable,
    CommonModule,
    EmailModule,
    FormItemModule,
    FormsModule,
    QimaBlockBannerModule,
    QimaButtonModule,
    QimaInputCheckboxModule,
    QimaInputMultipleEmailModule,
    QimaInputSearchSelectModule,
    QimaInputSelectModule,
    QimaInputTextareaModule,
    QimaInputTextModule,
    QimaMenuItemTextModule,
    QimaMenuModule,
    ReactiveFormsModule,
    TrackByUuidDirective,
    TranslateModule,
    QimaIconModule,
  ],
})
export class WelcomeAccountInfoComponent<T> implements OnInit {
  @ViewChild('submitRef')
  public submitRef: QimaOptionalType<ElementRef<HTMLElement>> = undefined;

  public readonly bannerTypeInfo = EQimaBlockBannerType.INFO;
  public readonly orientationVertical = EOrientation.VERTICAL;
  public readonly labelVerticalTop = EFormItemVerticalAlign.TOP;
  public readonly inputSizeLarge = EQimaInputSize.LARGE;
  public readonly buttonWidthFull = EQimaButtonWidth.FULL;
  public readonly buttonSizeLarge = EQimaButtonSize.LARGE;
  public countries: ISelect[] = [];
  public states: ISelect[] = [];
  public welcomeAccountInfoForm!: FormGroup<ControlsOf<IWelcomeAccountInfo>>;
  public marketingJsonData!: IMarketingListDom;
  public marketingSelects: IMarketingSelect[] = [];
  public subLabel = '';
  public subSelects: IMarketingSelect[] = [];
  public subSubSelects: IMarketingSelect[] = [];
  public sameContact = new FormControl(EQimaCheckboxState.UNCHECKED);
  public contactFirstName = new FormControl('');
  public contactLastName = new FormControl('');
  public contactCompanyName = new FormControl('');
  public checkboxStateEnum = EQimaCheckboxState;

  private _userData!: IUser;

  public constructor(
    public readonly qimaIcon: QimaIconService,
    @Inject(DialogRef) private readonly _dialogRef: DialogRef<T>,
    private readonly _accountService: AccountService,
    private readonly _appAuthenticationService: AppAuthenticationService,
    private readonly _sppScrollService: AppScrollService,
    private readonly _appSnackbarService: AppSnackbarService,
    private readonly _appParamsService: AppParamsService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _welcomeAccountInfoService: WelcomeAccountInfoService
  ) {}

  public ngOnInit(): void {
    this._initAccountData();
    this._initForm();
    this._watch();
  }

  public onSubmit(): void {
    this.welcomeAccountInfoForm.markAllAsTouched();
    this.welcomeAccountInfoForm.updateValueAndValidity();

    if (!this.welcomeAccountInfoForm.valid) {
      this._sppScrollService.scrolledByClassName('.scroll-area .ng-invalid');

      return;
    }

    this._welcomeAccountInfoService
      .updateContactDetails$(this.welcomeAccountInfoForm.value as IWelcomeAccountInfo)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (user: IUser | null): void => {
          if (!user) {
            return;
          }

          this._appSnackbarService.showSnackbar('WELCOME_ACCOUNT_INFO.SUBMIT_SUCCESS', EQimaSnackbarType.SUCCESS);
          this._dialogRef.close();
          // If the user has opened multiple windows, we need to update userData in all windows
          const channel = new BroadcastChannel(EChannelKeys.UPDATE_USER_DATA);

          channel.postMessage({ user });
        },
        error: (): void => {
          this._appSnackbarService.showSnackbar('COMMENT.NO_EMPTY', EQimaSnackbarType.ERROR);
        },
      });
  }

  public onHearFromItemClick(hearFrom: IMarketingSelect): void {
    const isHearFromTradeShow = hearFrom.submenu === EMarketingCategoryKey.TRADE_SHOWS;
    const key = isHearFromTradeShow ? EMarketingCategoryKey.TRADE_SHOW_COUNTRIES : hearFrom.submenu;

    this.subLabel = isHearFromTradeShow ? 'WELCOME_ACCOUNT_INFO.TRADE_SHOW_LABEL' : 'WELCOME_ACCOUNT_INFO.HOW_KNOW_US_PLACEHOLDER';
    this.subSelects = this._welcomeAccountInfoService.getSelectsByKey(this.marketingJsonData, key);

    if (isHearFromTradeShow) {
      this.subSelects.sort((a, b): number => a.label.localeCompare(b.label));
    }

    if (this.subSelects.length > 0) {
      this.welcomeAccountInfoForm.controls.hearFromSub.addValidators(Validators.required);
      this.welcomeAccountInfoForm.controls.hearFromSub.updateValueAndValidity();
    } else {
      this.welcomeAccountInfoForm.controls.hearFromSub.removeValidators(Validators.required);
      this.welcomeAccountInfoForm.controls.hearFromSub.updateValueAndValidity();
    }

    this.subSubSelects = [];
    this._changeDetectorRef.markForCheck();
  }

  public onHearFromSubItemClick(hearFromSub: IMarketingSelect): void {
    if (this.welcomeAccountInfoForm.controls.hearFrom.value === EMarketingCategoryKey.TRADE_SHOW) {
      const tradeShows = this._welcomeAccountInfoService.getSelectsByKey(this.marketingJsonData, EMarketingCategoryKey.TRADE_SHOWS);

      this.subSubSelects = tradeShows.filter((show): boolean => show.location === hearFromSub.code);
      this.welcomeAccountInfoForm.controls.hearFromSubSub.addValidators(Validators.required);
      this.welcomeAccountInfoForm.controls.hearFromSubSub.updateValueAndValidity();
    } else {
      this.subSubSelects = [];
      this.welcomeAccountInfoForm.controls.hearFromSubSub.removeValidators(Validators.required);
      this.welcomeAccountInfoForm.controls.hearFromSubSub.updateValueAndValidity();
    }

    this._changeDetectorRef.markForCheck();
  }

  private _watch(): void {
    this._watchCountries$().pipe(untilDestroyed(this)).subscribe();
    this._watchSelectedCountry$().pipe(untilDestroyed(this)).subscribe();
    this._watchSameContact$().pipe(untilDestroyed(this)).subscribe();
    this._watchMarketingList$().pipe(untilDestroyed(this)).subscribe();
    this._watchHearFrom$().pipe(untilDestroyed(this)).subscribe();
    this._watchHearFromSub$().pipe(untilDestroyed(this)).subscribe();
  }

  private _initForm(): void {
    this.welcomeAccountInfoForm = this._welcomeAccountInfoService.buildWelcomeAccountInfoForm(this._userData);
    this._changeDetectorRef.markForCheck();
  }

  private _initAccountData(): void {
    this._userData = this._appAuthenticationService.getCurrentUser();
    this.contactFirstName.setValue(this._userData.contacts.main.givenName);
    this.contactLastName.setValue(this._userData.contacts.main.familyName);
    this.contactCompanyName.setValue(this._userData.company.name);
    this.sameContact.setValue(this._userData.contacts.billing.sameAsMainContact ? EQimaCheckboxState.CHECKED : EQimaCheckboxState.UNCHECKED);
  }

  private _watchCountries$(): Observable<QimaNullableType<ISelect[]>> {
    return this._appParamsService.getCountryOrRegions$().pipe(
      tap({
        next: (data): void => {
          this.countries = data ?? [];
          this.welcomeAccountInfoForm.controls.companyCountry.patchValue(this._userData.company.country ?? '');
          this._changeDetectorRef.markForCheck();
        },
      })
    );
  }

  private _watchStates$(): Observable<QimaNullableType<ISelect[]>> {
    return this._accountService.getStatesByCountryName$(this.welcomeAccountInfoForm.controls.companyCountry.value).pipe(
      tap({
        next: (data): void => {
          this.states = data ?? [];

          if (this.states.every((state): boolean => state.value !== this.welcomeAccountInfoForm.controls.companyState.value)) {
            this.welcomeAccountInfoForm.controls.companyState.setValue('');
          }

          this._changeDetectorRef.markForCheck();
        },
      })
    );
  }

  private _watchSelectedCountry$(): Observable<string> {
    return this.welcomeAccountInfoForm.controls.companyCountry.valueChanges.pipe(
      debounceTime(200),
      tap({
        next: (country): void => {
          if (country) {
            this.states = [];
            this._watchStates$().pipe(untilDestroyed(this)).subscribe();
          }

          this._changeDetectorRef.markForCheck();
        },
      })
    );
  }

  private _watchSameContact$(): Observable<EQimaCheckboxState | null> {
    return this.sameContact.valueChanges.pipe(
      tap({
        next: (): void => {
          if (this.sameContact.value === EQimaCheckboxState.CHECKED) {
            this.welcomeAccountInfoForm.controls.billingGivenName.patchValue(this.contactFirstName.value ?? '');
            this.welcomeAccountInfoForm.controls.billingFamilyName.patchValue(this.contactLastName.value ?? '');
            this.welcomeAccountInfoForm.controls.billingEmail.patchValue(this._userData.contacts.main.email ?? '');
          } else {
            this.welcomeAccountInfoForm.controls.billingGivenName.patchValue('');
            this.welcomeAccountInfoForm.controls.billingFamilyName.patchValue('');
            this.welcomeAccountInfoForm.controls.billingEmail.patchValue('');
          }

          this._changeDetectorRef.markForCheck();
        },
      })
    );
  }

  private _watchHearFrom$(): Observable<string> {
    return this.welcomeAccountInfoForm.controls.hearFrom.valueChanges.pipe(
      tap({
        next: (): void => {
          this.welcomeAccountInfoForm.controls.hearFromSub.patchValue('');
          this.welcomeAccountInfoForm.controls.hearFromSubSub.patchValue('');
          this._changeDetectorRef.markForCheck();
        },
      })
    );
  }

  private _watchHearFromSub$(): Observable<string> {
    return this.welcomeAccountInfoForm.controls.hearFromSub.valueChanges.pipe(
      tap({
        next: (): void => {
          this.welcomeAccountInfoForm.controls.hearFromSubSub.patchValue('');
          this._changeDetectorRef.markForCheck();
        },
      })
    );
  }

  private _watchMarketingList$(): Observable<IMarketingListDom> {
    return this._welcomeAccountInfoService.getMarketingList$().pipe(
      tap({
        next: (data): void => {
          this.marketingJsonData = data;
          this.marketingSelects = this._welcomeAccountInfoService.getSelectsByKey(data, EMarketingCategoryKey.WHERE_HEAR);
          this._changeDetectorRef.markForCheck();
        },
      })
    );
  }
}
