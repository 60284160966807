import { Injectable } from '@angular/core';

@Injectable()
export class ServiceTypeService {
  /**
   * @description
   * Converts a service type code used internally to a service type text used for display purposes.
   * @param {string} serviceTypeCode - The service type code used internally.
   * @returns {string} - The service type text used for display purposes.
   */
  public getServiceTypeTextFromCode(serviceTypeCode: string): string {
    return serviceTypeCode.toUpperCase() === 'SP' ? 'SC' : serviceTypeCode;
  }
}
