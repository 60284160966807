<div
  *ngIf="data"
  class="currency-view"
>
  <div class="currency-view__code">{{ data.code }}</div>
  <div
    class="currency-view__value"
    qimaAutoTooltip
    [qimaAutoTooltipStyle]="qimaTooltip.style.DARK"
    [qimaAutoTooltipPosition]="qimaTooltip.position.TOP_RIGHT"
    [qimaAutoTooltipText]="data.value || 0 | number: '1.' + (data.decimal ? data.decimal : '2')"
    [qimaAutoTooltipStrategy]="qimaTooltip.strategy.ON_HOVER"
    [qimaAutoTooltipHasArrow]="true"
  >
    {{ data.value || 0 | number: '1.' + (data.decimal ? data.decimal : '2') }}
  </div>
</div>
