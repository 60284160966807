import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { ESuperMasterSelectorType } from '@aca-new/app/shared/components/data-table/shared/components/super-master-selector/shared/models/enums/super-master-selector-type.enum';
import { ISelect } from '@aca-new/app/shared/models/interfaces/select.interface';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { EQimaInputSelectStyle } from '@qima/ngx-qima';
import { sortBy } from 'lodash/index';

@UntilDestroy()
@Component({
  selector: 'app-super-master-selector',
  templateUrl: './super-master-selector.component.html',
  styleUrls: ['./super-master-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperMasterSelectorComponent implements OnInit {
  @Output('superMasterSelectorClick')
  public readonly userClick: EventEmitter<string> = new EventEmitter<string>();

  public masterCompanies: ISelect[] = [];
  public currentCompany: string = ESuperMasterSelectorType.ALL;
  public selectStyle: EQimaInputSelectStyle = EQimaInputSelectStyle.PRIMARY;

  public constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _translateService: TranslateService,
    private readonly _appAuthenticationService: AppAuthenticationService
  ) {}

  public ngOnInit(): void {
    this._initData();
    this._watchLanguage();
  }

  public onCompanyClick(id: string): void {
    this.userClick.emit(id);
  }

  private _initData(): void {
    const userData: IUser = this._appAuthenticationService.getCurrentUser();
    let companiesLists: ISelect[] = [];

    userData?.company?.superMaster?.masterCompanies?.forEach((val): void => {
      companiesLists.push({
        label: val.companyName.toLowerCase(),
        value: val.userId,
      });
    });
    companiesLists = sortBy(companiesLists, 'label');
    companiesLists.unshift(
      {
        label: 'COMMON.ALL_ACCOUNTS',
        value: ESuperMasterSelectorType.ALL,
      },
      {
        label: `${this._translateService.instant('COMMON.YOU')} ${userData?.company?.name}`,
        value: userData?.id ?? '',
      }
    );
    this.masterCompanies = companiesLists;
    this._changeDetectorRef.markForCheck();
  }

  private _watchLanguage(): void {
    this._translateService.onLangChange.pipe(untilDestroyed(this)).subscribe((): void => {
      this._initData();
      this._changeDetectorRef.markForCheck();
    });
  }
}
