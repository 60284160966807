import { ILabTestingInvoices, ILabTestingOrderFee } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/lab-testing-invoice.interface';
import { IOrderFee } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/order-fee.interface';
import { OrderFeeService } from '@aca-new/app/pages/payments/pages/payments-content/shared/services/order-fee/order-fee.service';
import { IEvidenceUploadStatus } from '@aca-new/app/pages/payments/shared/models/interfaces/evidence-upload-status.interface';
import { IPaymentEvidence } from '@aca-new/app/pages/payments/shared/models/interfaces/payment-evidence.interface';
import { IPayment } from '@aca-new/app/pages/payments/shared/models/interfaces/payment.interface';
import { AbstractDefaultPaymentService } from '@aca-new/app/pages/payments/shared/services/abstract-default-payment/abstract-default-payment.service';
import { PaymentsService } from '@aca-new/app/pages/payments/shared/services/payment/payments.service';
import { CurrencyTableCellComponent } from '@aca-new/app/shared/components/currency-table-cell/currency-table-cell.component';
import { ESortOrder } from '@aca-new/app/shared/components/data-table/shared/models/enums/sort-order.enum';
import { DataTableResponseType, IDataTableData, PageItemType } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-data.interface';
import { IDataTableQueryParams } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-query-params.interface';
import { DialogConfirmComponent } from '@aca-new/app/shared/components/modal/shared/components/dialog-confirm/dialog-confirm.component';
import { DialogExportComponent } from '@aca-new/app/shared/components/modal/shared/components/dialog-export/dialog-export.component';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { ETableDataType } from '@aca-new/app/shared/components/table/shared/enums/table-data-type.enum';
import { ETableHeaderLayoutMode } from '@aca-new/app/shared/components/table/shared/enums/table-header-layout-mode.enum';
import { ITableBodyCell, ITableBodyRow } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { ITableHeader } from '@aca-new/app/shared/components/table/shared/interfaces/table-header.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { CHECKBOX, PRODUCT_NAME, REF_NO } from '@aca-new/app/shared/constants/table-header.constants';
import { IFileResponse } from '@aca-new/app/shared/interfaces/file-response.interface';
import { EDocType } from '@aca-new/app/shared/models/enums/doc-type.enum';
import { EServiceType } from '@aca-new/app/shared/models/enums/service-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { IUrl } from '@aca-new/app/shared/models/interfaces/url.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { AppFileDownloadService } from '@aca-new/app/shared/services/file-services/app-file-download.service';
import { AppFileService } from '@aca-new/app/shared/services/file-services/app-file.service';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EQimaCheckboxState, EQimaTableHeadCellSort, IQimaFile, QimaOptionalType } from '@qima/ngx-qima';
import { assign, cloneDeep } from 'lodash/index';
import { CookieService } from 'ngx-cookie';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

interface ILtResponse {
  content?: {
    data: ILabTestingOrderFee[];
    itemsCount: number;
    totalPages: number;
  };
  message: string | null;
}

interface ILtInvoice {
  id: string;
  invoiceNo: string;
  labOrderNo: string;
  orderId: string;
  reportNo: string;
}

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class LtPaymentService extends AbstractDefaultPaymentService implements IPayment {
  public readonly userId = this._storageService.getItem(EStorageKeys.USER_ID);

  public paidBookingsTableHeaders: ITableHeader[] = [
    CHECKBOX,
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      key: 'issueDate',
      isFrontSort: true,
      label: 'TABLE.REPORT_DATE',
      type: ETableDataType.STRING,
      width: '124',
    },
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      key: 'officeName',
      isFrontSort: true,
      label: 'TABLE.LOCATION',
      type: ETableDataType.STRING,
      width: '124',
    },
    assign(cloneDeep(PRODUCT_NAME), {
      key: 'orderDescription',
      isFrontSort: true,
    }),
    assign(cloneDeep(REF_NO), {
      key: 'labOrderNo',
      isFrontSort: true,
    }),
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      key: 'reportNo',
      isFrontSort: true,
      label: 'TABLE.REPORT_NO',
      type: ETableDataType.STRING,
      width: '124',
    },
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      key: 'invoiceNo',
      isFrontSort: true,
      label: 'TABLE.INVOICE',
      type: ETableDataType.LINK,
      width: '202',
      cellClick: (cellData: Record<string, unknown>): void => {
        this.getInvoiceInfo(cellData.invoiceNo as string, cellData);
      },
    },
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      label: 'TABLE.AMOUNT',
      key: 'totalAmount',
      isFrontSort: true,
      type: ETableDataType.CUSTOMIZED,
      sortFunction: (s1, s2, order): number => this.currencySortFunction(s1 as ITableBodyCell, s2 as ITableBodyCell, order),
      mode: ETableHeaderLayoutMode.STICKY_RIGHT,
      cellTransform(cellData: ITableBodyCell, pageItem): void {
        cellData.customizedCellConfiguration = {
          component: CurrencyTableCellComponent,
          data: {
            code: `${(pageItem?.currency as string) || 'USD'}`,
            value: pageItem?.totalAmount,
          },
        };
      },
      width: '115',
    },
  ];

  public paidBookingsTableUrl: IUrl = {
    url: `${SERVER_URL}/user/${this.userId}/lt/order/paid/invoices?`,
    params: `isPaid=1&officeId={officeId}&page=1&size=10&direction=desc&sortingParameters=issueDate`,
  };

  public unpaidBookingsTableHeaders: ITableHeader[] = cloneDeep(this.paidBookingsTableHeaders);

  public unpaidBookingsTableUrl: IUrl = {
    url: `${SERVER_URL}/user/${this.userId}/lt/order/Unpaid/invoices?`,
    params: `isPaid=0,2&officeId={officeId}&page=1&size=10&direction=desc&sortingParameters=issueDate`,
  };

  public queryParams: IDataTableQueryParams = {
    keyword: '',
    pageSize: 1,
    page: 1,
    sortField: 'issueDate',
    sortType: ESortOrder.DESC,
  };

  public constructor(
    private readonly _overlayService: AppOverlayService,
    private readonly _appFileDownloadService: AppFileDownloadService,
    private readonly _storageService: StorageService,
    private readonly _dialog: DialogService,
    private readonly _cookieStorage: CookieService,
    private readonly _appFileService: AppFileService,
    private readonly _httpService: HttpService,
    private readonly _orderFeeService: OrderFeeService,
    private readonly _appUserSettingService: AppUserSettingService,
    _paymentsService: PaymentsService
  ) {
    super(_paymentsService);
  }

  public isEnablePayByTransfer(): boolean {
    return this._appUserSettingService.getEnableLTAdvancedPayment();
  }

  public exportPaidBookings(): void {
    this._dialog.open(DialogExportComponent, {
      data: {
        filename: 'Lab Testing Paid Payment List({startDate} - {endDate}).xlsx',
        title: 'PAYMENTS.EXPORT_PAYMENT_LIST',
        url: `${SERVER_URL}/user/${this.userId}/lt-payment-export?serviceType=LT&isPaid=yes&startDate={start}&endDate={end}`,
      },
    });
  }

  public exportUnpaidBookings(): void {
    this._dialog.open(DialogExportComponent, {
      data: {
        filename: 'Lab Testing UnPaid Payment List({startDate} - {endDate}).xlsx',
        title: 'PAYMENTS.EXPORT_PAYMENT_LIST',
        url: `${SERVER_URL}/user/${this.userId}/lt-payment-export?serviceType=LT&isPaid=no&startDate={start}&endDate={end}`,
      },
    });
  }

  public getInvoiceInfo(invoiceNumber: string, rowData?: Record<string, unknown>): void {
    if (!invoiceNumber) {
      return;
    }

    this._overlayService.updateIsLoading(true);

    const rowDataId = rowData?.['id'] as string;
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    let url = `${SERVER_URL}/user/${userId}/lt/order/invoice/${rowDataId}`;
    const token = this._cookieStorage.get(EStorageKeys.AUTH_TOKEN) ?? '';
    const last50Token: string = token.substring(token.length - 50);
    const sessionId = this._cookieStorage.get(EStorageKeys.SESSION_ID);

    url = `${url}?sessionId=${sessionId}&code=${last50Token}`;

    this._appFileDownloadService
      .downloadViaBlob$(url, `${invoiceNumber}.pdf`, [])
      .pipe(untilDestroyed(this))
      .subscribe((_isSuccess: boolean): void => {
        /* Do nothing */
      });
    this._overlayService.updateIsLoading(false);
  }

  public transformSourceData(data: unknown, pageSize: number): DataTableResponseType {
    const ltData: ILtResponse = data as ILtResponse;
    const pageItems: ILabTestingOrderFee[] = (ltData.content?.data || []).map((item): ILabTestingOrderFee => {
      return {
        ...item,
        serviceTypeText: EServiceType.LT,
      };
    });

    return {
      pageNo: 1,
      totalPageNum: Math.ceil(ltData.content?.itemsCount || 0 / pageSize),
      totalSize: ltData.content?.itemsCount || 0,
      pageItems,
      countByFields: null,
    } as unknown as IDataTableData;
  }

  public override payByTransfer$(orderIds: string[], _orderFees: IOrderFee[], uploadFiles: IQimaFile[], orderData?: ITableBodyRow[]): Observable<IHttpResponseBody<undefined>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    let ltEvidencesList: IPaymentEvidence[] = [];
    const url = `${SERVER_URL}/lt/proxy/order/invoice/payment-evidence`;
    const uploadObservables = uploadFiles.map(
      (file: IQimaFile): Observable<IEvidenceUploadStatus> =>
        this._appFileService.uploadFiles$(orderIds.join(','), EDocType.WAITING_FOR_PAYMENT, file).pipe(
          map(
            (res: QimaNullableType<IFileResponse>): IEvidenceUploadStatus => ({
              code: 'success',
              result: res,
              source: file,
            })
          ),
          catchError(
            (error): Observable<IEvidenceUploadStatus> =>
              of({
                code: 'error',
                result: error,
                source: file,
              })
          )
        )
    );

    return forkJoin(uploadObservables).pipe(
      map((results: IEvidenceUploadStatus[]): IPaymentEvidence[] => {
        ltEvidencesList = [];
        results.forEach((uploadResult): void => {
          if (uploadResult.code === 'success' && uploadResult.result) {
            ltEvidencesList.push({
              description: uploadResult.result.comments,
              fileHashcode: uploadResult.result.id,
              fileName: uploadResult.result.fileName,
              fileSize: uploadResult.result.fileSize,
            });
          }
        });

        return ltEvidencesList;
      }),
      switchMap((): Observable<IHttpResponseBody<undefined>> => {
        const invoiceList = orderData?.map((item): ILtInvoice => item.originData as ILtInvoice);

        return this._httpService.httpClient.post<IHttpResponseBody<undefined>>(
          url,
          { evidences: ltEvidencesList, invoices: invoiceList },
          {
            observe: 'body',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            headers: { 'AI-User-Id': userId },
          }
        );
      })
    );
  }

  public override getOrderFees$(
    orderIds: string[],
    _orderFees: IOrderFee[],
    _orderIdToRefMap: Record<string, PageItemType>
  ): Observable<QimaOptionalType<IOrderFee[] | ILabTestingInvoices>> {
    return this._orderFeeService.getLTOrderFees$(orderIds).pipe(
      map((invoices: QimaOptionalType<ILabTestingInvoices>): IOrderFee[] => {
        if (!invoices?.invoiceList?.length) {
          return [];
        }

        const fees: IOrderFee[] = invoices.invoiceList.map((item: ILabTestingOrderFee, index: number): IOrderFee => {
          if (item.invoiceNo && item.id && item.currency) {
            const itemName = `${index + 1}/ Lab Testing Invoice: ${item.invoiceNo}`;

            return {
              orderId: item.id,
              invoiceNo: item.invoiceNo,
              officeId: item.officeId,
              itemName,
              quantity: '1',
              amount: item.amountRemaining.toString(),
              unitPrice: item.amountRemaining.toString(),
              amountUnit: item.currency,
            };
          }

          return {} as IOrderFee;
        });

        fees.push({
          orderId: null,
          invoiceNo: undefined,
          officeId: undefined,
          itemName: 'ADYEN fees',
          quantity: '1',
          amount: invoices.processingFees.toFixed(2).toString(),
          unitPrice: invoices.processingFees.toFixed(2).toString(),
          amountUnit: invoices.invoiceList[0].currency,
        });

        return fees;
      })
    );
  }

  public beforeCheckboxClick(rows: ITableBodyRow[]): boolean {
    const selectedData: ITableBodyRow[] = rows.filter((row): boolean => row.viewData[0].checkState === EQimaCheckboxState.CHECKED);

    if (selectedData.length === 0) {
      return true;
    }

    const { officeId: firstOfficeId, currency: firstCurrency } = selectedData[0].originData as ILabTestingOrderFee;
    const canClick: boolean = selectedData.every((row: ITableBodyRow): boolean => {
      const { officeId, currency } = row.originData as ILabTestingOrderFee;

      return officeId === firstOfficeId && currency === firstCurrency;
    });

    if (!canClick) {
      this._dialog.open(DialogConfirmComponent, {
        data: {
          title: 'COMMON.ERROR',
          content: 'PAYMENTS.LT_SELECT_LIMIT',
          confirmButtonLabel: 'COMMON.OK',
          hasCancel: false,
        },
      });
    }

    return canClick;
  }
}
